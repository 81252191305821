import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import CalculateIcon from '@mui/icons-material/Calculate';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormLabel from '@mui/material/FormLabel';
import Slider from '@mui/material/Slider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Grid } from '@mui/material';

const defaultValues = {
  age: 0,
  weight: 0,
  feet: 0,
  inches: 0,
  sex: "f",
  activity: 0,
  carb: 40,
  fat: 30,
  protein: 30
};

const initialResults = {
  calories: 0,
  carb: 0,
  fat: 0,
  protein: 0
};

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://fitnutkatie.com/">
        fitnutkatie.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function ResultsHeading(props) {
  const total = props.values.carb + props.values.fat + props.values.protein;

  let message = "";
  if (total > 100) {
    message = "Exceed"
  }
  else if (total < 100) {
    message = "Below"
  }

  if (message !== "") {
    return (
      <Typography
        component="h1"
        variant="h5"
        color='error'
        sx={{ mt: 2 }}
      >
        Results {message} 100%
      </Typography>
    )
  }
  else {
    return (
      <Typography
        component="h1"
        variant="h5"
        sx={{ mt: 2 }}
      >
        Results
      </Typography>
    );
  }
}

function Results(props) {
  const marginTop = 2;
  return (
    <Grid container columnSpacing={6} rowSpacing={1}>
      <Grid item xs={12} sm={6} md={6}>
        <TextField
          variant='outlined'
          inputProps={{ readOnly: true }}
          value={props.results.calories}
          label="Daily Caloric Intake"
          fullWidth
          sx={{ mt: marginTop }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <TextField
          variant='outlined'
          inputProps={{ readOnly: true }}
          value={props.results.carb + " g"}
          label="Carbohydrates"
          fullWidth
          sx={{ mt: marginTop }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <TextField
          variant='outlined'
          inputProps={{ readOnly: true }}
          value={props.results.fat + " g"}
          label="Fat"
          fullWidth
          sx={{ mt: marginTop }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <TextField
          variant='outlined'
          inputProps={{ readOnly: true }}
          value={props.results.protein + " g"}
          label="Protein"
          fullWidth
          sx={{ mt: marginTop }}
        />
      </Grid>
    </Grid>
  )
}

const theme = createTheme();

function Calculator() {
  const [values, setValues] = React.useState(defaultValues);
  const [results, setResults] = React.useState(initialResults);

  const calculateBmr = () => {
    const weightInKg = values.weight * 0.45359237;
    const heightCm = (values.feet * 12 + values.inches) * 2.54;

    if (values.sex === "f") {
      return (655.1 + (9.563 * weightInKg) + (1.85 * heightCm) - (4.676 * values.age));
    }
    else if (values.sex === "m") {
      return (66.5 + (13.75 * weightInKg) + (5.003 * heightCm) - (6.75 * values.age));
    }
  };

  const calcluateDailyCalories = (bmr) => {
    if (values.activity === 0) {
      return bmr * 1.2;
    }
    else if (values.activity === 1) {
      return bmr * 1.375;
    }
    else if (values.activity === 2) {
      return bmr * 1.55;
    }
    else if (values.activity === 3) {
      return bmr * 1.725;
    }
    else if (values.activity === 4) {
      return bmr * 1.9;
    }
  };

  const calculateMacro = (caloriesPerGram, calories, percentage) => {
    return (calories * percentage) / caloriesPerGram;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newCalories = Math.round(calcluateDailyCalories(calculateBmr()));

    const newResults = {
      calories: newCalories,
      carb: Math.round(calculateMacro(4, newCalories, values.carb / 100)),
      fat: Math.round(calculateMacro(9, newCalories, values.fat / 100)),
      protein: Math.round(calculateMacro(4, newCalories, values.protein / 100))
    };

    setResults(newResults)
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ mt: 8 }}>
        <Header />
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Grid container columnSpacing={6} rowSpacing={1}>
            {/* First Row Start */}
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="age"
                label="Age"
                name="age"
                autoComplete="age"
                autoFocus
                onFocus={(e) => e.target.select()}
                type="number"
                inputProps={{ min: 15, max: 130 }}
                value={values.age}
                onChange={(e) => setValues({ ...values, age: Number(e.target.value) })}
              />
            </Grid>
            {/* <AgeInput values setValues/> */}
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="weight"
                label="Weight (lbs)"
                type="number"
                onFocus={(e) => e.target.select()}
                inputProps={{ min: 50, max: 900 }}
                value={values.weight}
                onChange={(e) => setValues({ ...values, weight: Number(e.target.value) })}
              />
            </Grid>
            {/* First Row End  */}
            {/* Second Row Start */}
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="feet"
                label="Feet"
                type="number"
                onFocus={(e) => e.target.select()}
                inputProps={{ min: 3, max: 8 }}
                value={values.feet}
                onChange={(e) => setValues({ ...values, feet: Number(e.target.value) })}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="inches"
                label="Inches"
                name="inches"
                autoComplete="inches"
                type="number"
                onFocus={(e) => e.target.select()}
                inputProps={{ min: 1, max: 11 }}
                value={values.inches}
                onChange={(e) => setValues({ ...values, inches: Number(e.target.value) })}
              />
            </Grid>
            {/* Second Row End */}
            {/* Third Row Start */}
            <Grid item xs={12} sm={4} md={4}>
              <FormLabel>Sex</FormLabel>
              <ToggleButtonGroup
                value={values.sex}
                exclusive
                fullWidth
                margin="normal"
                sx={{ mt: 1, mb: 2 }}
                color='secondary'
                onChange={(e) => setValues({ ...values, sex: e.target.value })}
              >
                <ToggleButton value="f">F</ToggleButton>
                <ToggleButton value="m">M</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <FormLabel>Activity Level</FormLabel>
              <ToggleButtonGroup
                value={values.activity}
                exclusive
                fullWidth
                margin="normal"
                sx={{ mt: 1, mb: 2 }}
                color='secondary'
                onChange={(e) => setValues({ ...values, activity: Number(e.target.value) })}
              >
                <ToggleButton value={0}>None</ToggleButton>
                <ToggleButton value={1}>Light</ToggleButton>
                <ToggleButton value={2}>Moderate</ToggleButton>
                <ToggleButton value={3}>Heavy</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            {/* Third Row End */}
            {/* Forth Row Start */}
            <Grid item xs={12} sm={12} md={4}>
              <FormLabel>Carbohydrate</FormLabel>
              <Slider
                step={5}
                min={30}
                max={65}
                marks
                color='secondary'
                valueLabelDisplay="auto"
                value={values.carb}
                onChange={(e) => {
                  setValues({ ...values, carb: Number(e.target.value) });
                  setResults({ ...results, carb: Math.round(calculateMacro(4, results.calories, values.carb / 100)) });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <FormLabel>Fat</FormLabel>
              <Slider
                step={5}
                min={10}
                max={40}
                marks
                color='secondary'
                valueLabelDisplay="auto"
                value={values.fat}
                onChange={(e) => {
                  setValues({ ...values, fat: Number(e.target.value) });
                  setResults({ ...results, fat: Math.round(calculateMacro(9, results.calories, values.fat / 100)) });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <FormLabel>Protein</FormLabel>
              <Slider
                step={5}
                min={10}
                max={45}
                marks
                color='secondary'
                valueLabelDisplay="auto"
                value={values.protein}
                onChange={(e) => {
                  setValues({ ...values, protein: Number(e.target.value) });
                  setResults({ ...results, protein: Math.round(calculateMacro(4, results.calories, values.protein / 100)) });
                }}
              />
            </Grid>
            {/* Forth Row End */}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color='secondary'
            sx={{ mt: 3, mb: 2 }}
          >
            Calculate
          </Button>
        </Box>
        <ResultsHeading values={values} />
        <Results results={results} />
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

function Header() {
  return (
    <>
      <Center>
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <CalculateIcon />
        </Avatar>
      </Center>
      <Typography component="h1" variant="h5">
        Macro Calculator
      </Typography>
    </>
  )
}

function Center(props) {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      {props.children}
    </div>
  )
}

export default Calculator;